<template>
  <div  id="onetimeid">
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="600px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 600px" >
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" style="height: 966px" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-1 dialogTitle-text">Message</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleModal({ show: false }),submitForm()"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form lazy-validation class="pt-10">
          <v-row>
            <v-col cols="12" class="py-5">
              <div @click="playVideo()" style="cursor: pointer">
                <img
                 src="@/assets/thingsToDo/onetimevideo.png"
                 width="100%"
                 height="300px"
                  alt=""
                  class="playImg"
                />
              </div>
            </v-col>
          </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="12" class="px-2">
                <h3>Let's get started</h3>
                <label
                  style="
                    display: inline-block;
                    max-width: 100%;
                    margin-bottom: 60px;
                    font-weight: 700;
                    font-size: 16px;
                  "
                >
                  In order to complete the challenge and win the champion
                  medallion, you need to:<br />
                  <br />
                  Share with at least 3 contacts
                  <br />
                  <br />
                  <v-btn
                    width="30%"
                    dark
                    color="#7253CF"
                    class="btn text-capitalize"
                    @click="toggleInviteViaMailModal({ show: true })"
                  >
                    <v-icon size="15" style="margin-left: -45px"
                      >mdi-email-outline</v-icon
                    >&nbsp;
                    <span
                      style="
                        font-family: Roboto Slab;
                        font-size: 10px;
                        width: 55%;
                        letter-spacing: 0px;
                      "
                      >Share via Email</span
                    >
                  </v-btn>
                  &nbsp;&nbsp;
                  <v-btn
                    width="29%"
                    dark
                    color="#7253CF"
                    class="btn text-capitalize"
                    @click="toggleInviteViaSmsModal({ show: true })"
                  >
                    <img
                      src="@/assets/thingsToDo/textsms1.png"
                      width="20"
                      height="20"
                      style="margin-left: -45px"
                    />
                    <span
                      style="
                        font-family: Roboto Slab;
                        font-size: 10px;
                        width: 55%;
                        letter-spacing: 0px;
                      "
                      >Share via Text</span
                    >
                  </v-btn>
                  &nbsp;&nbsp;
                  <v-btn
                    @click="toggleShareFundraiserModal({ show: true })"
                    width="32%"
                    text
                    class="text-capitalize"
                    style="border: 2px solid #4a3290"
                  >
                    <v-icon
                      size="10"
                      color="#4A3290"
                      left
                      style="margin-left: -80px"
                      >mdi-share-variant-outline</v-icon
                    >
                    <span
                      style="
                        font-family: Roboto Slab;
                        font-size: 10px;
                        color: #4a3290;
                        width: 27%;
                        letter-spacing: 0px;
                        margin-left: -12px;
                      "
                    >
                      Social Media Share</span
                    >
                  </v-btn>
                  <br />
                  <br />
                  and Watch all 6 Wizfit videos
                </label>
              </v-col>
            </v-row>
            <v-row style="margin-top: -55px">
              <v-col cols="4" sm="2" class="py-4">
                <img
                  src="@/assets/publicDonationPage/wizfitVideoProgress/wellnessActive.png"
                  width="100%"
                  alt=""
                />
              </v-col>
              <v-col cols="4" sm="2" class="py-4">
                <img
                  src="@/assets/publicDonationPage/wizfitVideoProgress/imaginationActive.png"
                  width="100%"
                  alt=""
                />
              </v-col>
              <v-col cols="4" sm="2" class="py-4">
                <img
                  src="@/assets/publicDonationPage/wizfitVideoProgress/zeroHateActive.png"
                  width="100%"
                  alt=""
                />
              </v-col>
              <v-col cols="4" sm="2" class="py-4">
                <img
                  src="@/assets/publicDonationPage/wizfitVideoProgress/funActive.png"
                  width="100%"
                  alt=""
                />
              </v-col>
              <v-col cols="4" sm="2" class="py-4">
                <img
                  src="@/assets/publicDonationPage/wizfitVideoProgress/inspirationActive.png"
                  width="100%"
                  alt=""
                />
              </v-col>
              <v-col cols="4" sm="2" class="py-4">
                <img
                  src="@/assets/publicDonationPage/wizfitVideoProgress/teamWorkActive.png"
                  width="100%"
                  alt=""
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  API_ADMIN_STUDENT_MANAGEMENT_POST,
  API_ADMIN_STUDENT_MANAGEMENT_GET,
  API_GET_STUDENT_EVENT_VIDEO_PROGRESS,
} from "@/constants/APIUrls";
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
export default {
  name: "AlertMessageModal",
  components: {},
  data() {
    return {
      loading: false,
      alert2: true,
      alertparentemail: localStorage.getItem("parentemail"),
      parentEmail: "",
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getAlertMessageModalShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        this.toggleModal({ show: value });
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        setTimeout(() => {
          this.openModal();
        }, 100);
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleAlertMessageModal",
      showToast: "snackBar/showToast",
      toggleInviteViaMailModal: "publicDonationPage/toggleInviteMail",
      toggleInviteViaSmsModal: "publicDonationPage/toggleInviteSms",
      toggleShareFundraiserModal:
        "publicDonationPage/toggleShareFundraiserModal",
        toggleVideoModal: "videoSection/toggleModal"
    }),
    openModal() {},
    closeModal() {
      this.loading = false;
    },
    submitForm() {
      const successHandler = (res) => {
        console.log(res.data);
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["share_intimation"] = true;
      formData["student_id"] = this.student;
      formData["user_email"] =  this.parentEmail;
      Axios.request_PATCH(
        API_ADMIN_STUDENT_MANAGEMENT_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getStudentDetail() {
      const successHandler = (res) => {
        console.log(res.data.student_detail.parent_email);
        this.parentEmail = res.data.student_detail.parent_email;
       
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data);
      };

      let formData = {};
      formData["student_id"] = this.student;
      console.log(formData);

      Axios.request_GET(
        API_ADMIN_STUDENT_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
       playVideo() {
      this.toggleVideoModal({
        show: true,
        obj: this.videoList,
        src: this.videoList.video_url,
      });
     this.postVideoComplete();
    },
     postVideoComplete() {
      const successHandler = (res) => {
        console.log(res.data);
     this.videoList = res.data.event_video_watch_list[0];
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["video"] = 1;
      formData["student"] = this.$route.query.student;
      formData["watch_time"] = 2;
      formData["is_video_watched"] = true;

      Axios.request_POST(
        API_GET_STUDENT_EVENT_VIDEO_PROGRESS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
      getDetails() {
      this.videoflag = true;
      const successHandler = (res) => {
        console.log(res.data, "video");
        this.videoList = res.data.event_video_watch_list[0];
        console.log("@@@@@@@@@@@@@", this.videoList.video_url);
        console.log("@@@@@@@@@@@@@ is_watched", this.videoList.is_watched);
        localStorage.setItem("is_watched", this.videoList.is_watched);
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["student_id"] = this.student;
      Axios.request_GET(
        API_GET_STUDENT_EVENT_VIDEO_PROGRESS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    if (this.$route.query.student) {
      this.student = this.$route.query.student;
    }
     if(this.$route.query.student ){
      this.getStudentDetail();
      }
      this.getDetails();
       if(this.videoshowtime) {
    localStorage.setItem("alertshowfromonetime",true)
      setTimeout(() => {
        const elem = document.getElementById("onetimeid");
        elem.click();
      }, 3000);
    }
  },
};
</script>
